import { useOrderContainers, useOrderTicketTypes } from 'api/v2/orders';
import { useMemo, useReducer } from 'react';
import multiSelectOptions from 'utils/multiSelectOptions';
import targetValue from 'utils/targetValue';

export const MAX_TICKET_WEIGHT = 85000;
export const MIN_TICKET_WEIGHT = 100;

export const useContainerOptions = (orderId) => {
  const { data: containers } = useOrderContainers({ orderId });

  return useMemo(() => multiSelectOptions(
    (containers ?? []),
    'id',
    'containerNumber',
  ), [containers])
}
export const useMappedTicketTypes = orderId => {
  const { data: ticketTypes } = useOrderTicketTypes({ orderId });

  return useMemo(() => {
    if (!ticketTypes) {
      return [];
    }

    return ticketTypes.map(ticketType => ({
      value: ticketType.sandTicketTypeId,
      label: ticketType.name,
      disabled: Boolean(ticketType.isRequired),
    }));
  }, [ticketTypes]);
};

/**
 * @typedef {{
 *  ticketNo: string;
 *  weight: number;
 *  fileName: string;
 *  url: string;
 *  crop: any;
 *  pixelCrop: any;
 *  ticketType: number;
 *  containerIds: number[];
 * }} Ticket
 */

const initialTicket = {
  ticketNo: '',
  containerIds: [],
  fileName: '',
  url: '',
  crop: {},
};

/**
 *
 * @param {Ticket} state
 * @returns {Ticket}
 */
const TicketReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.data;
    case 'set_value':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'clear_state':
      return initialTicket;
    default:
      return state;
  }
};

export const useTicketForm = () => {
  /** @type {[Ticket, () => {}]} */
  const [data, dispatch] = useReducer(TicketReducer, initialTicket);

  const isValid = useMemo(
    () =>
      data.ticketNo.trim().length &&
      data.ticketType &&
      data.weight <= MAX_TICKET_WEIGHT &&
      data.weight >= MIN_TICKET_WEIGHT,
    [data],
  );

  const handlers = useMemo(
    () => ({
      valueChange: name => e => {
        if (name === 'file') {
          const file = e.target.files[0];
          dispatch({
            type: 'set_value',
            name: 'fileName',
            value: file.name,
          });

          const reader = new FileReader();
          reader.addEventListener(
            'load',
            () =>
              dispatch({
                type: 'set_value',
                name: 'url',
                value: reader.result,
              }),
            false,
          );
          reader.readAsDataURL(file);
        }

        dispatch({
          type: 'set_value',
          name,
          value: targetValue(e.value ? e.value : e),
        });
      },
      clear: () => dispatch({ type: 'clear_state' }),
    }),
    [],
  );

  return {
    data,
    isValid,
    handlers,
  };
};
