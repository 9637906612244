import { client } from 'api/client';
import { priceTypes } from 'components/globalInfo/priceTypes';

/**
 * @typedef {{
 *   id: number;
 *   type: keyof typeof priceTypes;
 *   comment: string;
 *   name: string;
 *   price: number;
 *   customerPrice: number;
 *   carrierPrice: number;
 *   customerPricePerTon?: number;
 *   carrierPricePerTon?: number;
 *   customerPricePerHour: number;
 *   carrierPricePerHour: number;
 *   baseConditionalValue: number;
 *   rangeType: number;
 *   billableValueField: number;
 *   mileage: number;
 *   endtoEndTime: number;
 * }} Price
 */

/**
 * @typedef {Price & {
 *   title: typeof priceTypes[keyof typeof priceTypes]['title'];
 * }} PriceWithTitle
 */

/**
 * @typedef {{
 *   total: number;
 *   carrierTotal: number;
 * }} Total
 */

/**
 *
 * @param {number} orderId
 * @returns {Promise<{
 *   price: PriceWithTitle[];
 *   total: Total;
 * }>}
 */
export const getOrderPriceBreakdown = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/price-breakdown`);
  /** @type {{ price: Price[]; total: Total; }} */
  const { price, total } = data.data;
  return {
    price: price.map(p => ({ ...p, title: priceTypes[p.type].title })),
    total,
  };
};
