import { useQuery } from '@tanstack/react-query';

import { getOrderPriceBreakdown } from './queries';

export const useOrderPriceBreakdown = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', Number(orderId), 'price-breakdown'],
    queryFn: () => getOrderPriceBreakdown(orderId),
    enabled: !!orderId,
  });
