import React, { useState } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/ManageCustomers';
import { deleteAlert } from 'components/sweetalert';
import Authorize from 'components/common/Authorize';
import Link from 'components/common/Link';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';

import { useDeleteLocation } from 'api/v2/locations';
import EditWellSite from '../WellSiteModal';

const WellSiteList = ({ wellSites, customers, setWellSite }) => {
  const { mutate: deleteLocation } = useDeleteLocation();
  const [showModalEdit, setShowModalEdit] = useState(false);

  const renderWellsiteItem = item => (
    <div key={item.id} className="row item-well">
      <div className="col-md-2">
        <h5 style={{ cursor: 'pointer' }}>
          #{item.id}
          <Link id={item.id} to="location-details">
            <span className="text-navy"> {item.name}</span>
          </Link>
        </h5>
      </div>
      <div className="col-md-3">
        <strong>
          <Icon icon="map-marker" /> Address:
        </strong>{' '}
        {[item.address, item.city, item.state]
          .filter(i => i?.length)
          .join(', ')}
        <br />
        <strong>
          <Icon icon="phone" /> Phone:
        </strong>
        {` ${item.contact_no?.length ? item.contact_no : 'N/A'}`}
        <br />
      </div>
      <div className="col-md-2">
        <strong>longitude: </strong>
        {item.longitude}
        <br />
        <strong>latitude: </strong>
        {item.latitude}
        <br />
      </div>
      <div className="col-md-3">
        <strong>Special instructions: </strong>
        {item.loading_site_special_instruction}
        <br />
      </div>

      <div className="col-md-1" style={{ marginLeft: 30 }}>
        <div className=" btn-group" style={{ marginTop: 5 }}>
          <Authorize
            abilityPermissions={[
              { resource: 'Locations', permissions: ['delete'] },
            ]}
            itemAccessGranted={
              item.accessLevel === 'owner' || item.accessLevel === 'shared'
            }>
            <Button
              onClick={() => deleteAlert(deleteLocation, item.id)}
              theme="small"
              colour="white"
              testSelector="admin-customers_wellsites_delete_btn">
              Delete
            </Button>
          </Authorize>

          <Authorize
            abilityPermissions={[
              { resource: 'Locations', permissions: ['update'] },
            ]}
            itemAccessGranted={
              item.accessLevel === 'shared' || item.accessLevel === 'owner'
            }>
            <Button
              onClick={() => {
                setShowModalEdit(true);
                setWellSite(item);
              }}
              theme="small"
              colour="white"
              testSelector="admin-customers_wellsites_edit_btn">
              Edit
            </Button>
          </Authorize>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {showModalEdit && (
        <EditWellSite
          modalTitle="Edit Location"
          customers={customers}
          showModal={showModalEdit}
          closeModal={() => setShowModalEdit(false)}
          action="edit"
        />
      )}
      {wellSites.map(renderWellsiteItem)}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  setWellSite: wellSite => dispatch(actions.setWellSite(wellSite)),
  editWellSite: (e, action, closeEdit) => {
    e.preventDefault();
    dispatch(actions.addEditWellSite(action, closeEdit));
  },
});

export const WellSiteListTest = WellSiteList; // for Tests

export default connect(
  null,
  mapDispatchToProps,
)(WellSiteList);
