import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import { isEqual } from 'lodash';

import {
  addOrder,
  downloadInvoicePdf,
  getJobsForDiversion,
  getOrders,
  divert,
  getOrder,
  modifyOrder,
  getOrderContainers,
  getOrderBreadcrumbs,
} from './queries';

export const useOrders = ({ params, page, pageSize }) =>
  useQuery({
    queryKey: ['orders', params, page, pageSize, 'list'],
    queryFn: () => getOrders({ params, page, pageSize }),
    placeholderData: (prevData, prevQuery) => {
      // Only keep previous data if page changes
      // also keep if startDate changes and no endDate
      if (
        (params?.startDate && !params?.endDate) ||
        isEqual(prevQuery?.queryKey?.[1], params)
      ) {
        return prevData;
      }
      return undefined;
    },
    // Require a start date and an end date
    enabled: params?.startDate ? !!params?.endDate : true,
  });

export const useOrder = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', Number(orderId)],
    queryFn: () => getOrder(orderId),
    enabled: !!orderId,
  });

export const useAddOrder = ({ jobId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: orderInfo => addOrder({ jobId, orderInfo }),
    onSuccess: () => {
      swal('Successfully Added Orders', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
      queryClient.invalidateQueries({ queryKey: ['orders'] });
    },
    onError: error =>
      swal('Unable to Add Orders', error.response?.data?.message, 'error'),
  });
};

export const useModifyOrder = ({ orderId }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: orderInfo => modifyOrder({ orderId, orderInfo }),
    onSuccess: ({ data }) => {
      swal('Success!', 'Successfully Modified Order', 'success');
      if (
        (Number(data.sandDemurrage.price) !== 0 &&
          !data.sandSiteDemurrageReasonId) ||
        (Number(data.wellDemurrage.price) !== 0 &&
          !data.wellSiteDemurrageReasonId)
      ) {
        // Don't invalidate yet, demurrage reason needed.
        return;
      }

      queryClient.invalidateQueries({ queryKey: ['orders'] });
      queryClient.invalidateQueries({ queryKey: ['job-plans'] });
    },
    onError: error =>
      swal('Unable to Modify Order', error.response?.data?.message, 'error'),
  });
};

export const useOrderContainers = ({ orderId, enabled }) =>
  useQuery({
    queryKey: ['orders', Number(orderId), 'containers'],
    queryFn: () => getOrderContainers(orderId),
    enabled: !!orderId && enabled,
  });

export const useOrderBreadcrumbs = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', Number(orderId), 'breadcrumbs'],
    queryFn: () => getOrderBreadcrumbs(orderId),
    enabled: !!orderId,
  });

export const useDownloadInvoicePdf = () =>
  useMutation({
    mutationFn: orderId => downloadInvoicePdf(orderId),
    onError: error =>
      swal(
        'Unable to Download Order Invoice',
        error.response?.data?.message,
        'error',
      ),
  });

export const useJobsForDiversion = ({
  customerId,
  equipmentId,
  jobId,
  carrierId,
  orderId,
}) =>
  useQuery({
    queryFn: () =>
      getJobsForDiversion({
        customerId,
        equipmentId,
        jobId,
        carrierId,
        orderId,
      }),
    placeholderData: [],
    queryKey: [
      'jobs-for-diversion',
      { customerId, equipmentId, carrierId, jobId },
    ],
    enabled: !!orderId,
  });

export const useDivertOrder = ({ orderId }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: data => divert({ orderId, ...data }),
    onSuccess: (_, data) => {
      swal(
        'Success!',
        `Successfully diverted order to job ${data.jobId}`,
        'success',
      );
      queryClient.invalidateQueries({ queryKey: ['orders'] });
    },
    onError: error =>
      swal('Unable to Divert Order', error.response?.data?.message, 'error'),
  });
};
